<template>
<section class="section-narrow">
    <div class="section-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <h1>{{$t('index.heading')}}</h1>

                    <p>{{$t('index.subtitle')}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'IndexPage',
    data () {
        return {}
    }
}
</script>
